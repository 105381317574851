<template>

	<div>
		<!-- Table Container Card -->
		<template>
			<div id="breadcrumb-alignment" class="mb-1">
				<div class="d-flex justify-content-start breadcrumb-wrapper">
					<b-breadcrumb :items="breadCrumb()"/>
				</div>
			</div>
		</template>

		<b-card no-body>
			<b-card-body>
				<b-row>
					<b-col cols="12" md="4" class="mb-md-0 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">            
						<v-select
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="sites"
							label="site_name"
							class="w-100"
							placeholder="Project Site"
							v-model="siteData"
							@input="resetData();"
							:clearable="false"
						>
							<template #selected-option="{ site_name }">
								<span v-if="site_name.length < 23">{{site_name}}</span>
								<span v-if="site_name.length >= 23">{{site_name.substring(0, 23) + '..'}}</span>
							</template>
						</v-select>
					</b-col>

					<b-col cols="12" md="4" class="mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
						<v-select
						v-model="locations"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						multiple
						label="name"
						:options="allLocations"
						placeholder="Select Locations"
						class="multiselect_muliple_options"
						/>
					</b-col>
					<b-col cols="12" md="4" class="mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
						<v-select
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="groupArray"
							class="w-100 multiselect_muliple_options"
							placeholder="Group"
							v-model="group"
							multiple
						/>
					</b-col>
					<b-col cols="12" md="4" class="mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
						<v-select
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="frequencies"
							label="name"
							class="w-100"
							placeholder="Frequency"
							v-model="frequencyData"
							:clearable="true"
						/>
					</b-col>

					<b-col cols="12" md="4" class="mb-0 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five" >
						<b-form-select v-model="assign_status" @change="crew_filter = ''">
							<b-form-select-option value="all" v-if="$store.getters.currentUser.role != 'crew'">{{'All'}}</b-form-select-option>
							<b-form-select-option value="myself" v-if="$store.getters.currentUser.role == 'crew'">{{'Myself'}}</b-form-select-option>
							<b-form-select-option value="unassigned" >{{'Unassigned'}}</b-form-select-option>
						</b-form-select>
					</b-col>
										
					<b-col cols="12" md="4" class="mb-0 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five" v-if="$store.getters.currentUser.role != 'crew'">
						<b-form-select v-model="crew_filter" :disabled="assign_status == 'unassigned'">
							<b-form-select-option value="">Select Crew</b-form-select-option>
							<b-form-select-option :value="crew._id" v-for="crew in allFilterCrews" :key="crew._id">{{crew.full_name}}</b-form-select-option>
						</b-form-select>
					</b-col>

					<b-col cols="12" md="4" class="mb-md-0 mb-0 mobile_tab_max_width_flex">
						
						<div class="d-flex align-items-center">
				          
				          <flat-pickr
				            v-model="date_range"
				            :config="date_config"
				            class="form-control flat-picker"
				            placeholder="Select Date Range"
				            @on-change="setDate()"
				          />

				        </div>
					</b-col>

					
				</b-row>
				
			</b-card-body>
		</b-card>

		<b-card no-body class="mb-0" v-if="siteData != null">
			
			<b-tabs pills justified class="margin_top_zero_mobile tab_ul button-padding table_button_tab">                
            
                <b-tab title="Pending" lazy @click="tab_status = 'open'" :active="tab_status == 'open'">
                	<template #title>
						<div class="inline-button-items">
							<span>Pending</span>
							<b-badge variant="warning" style="margin-left: 5px;" v-if="open > 0 ">
							  	<span>{{open}}</span>
							</b-badge>
						</div>
                  </template>
                  <Pending :siteData="siteData" :locations="locations" :crew_filter="crew_filter" :status="status" :start="start" :end="end" :tab_status="'open'" :frequencyData="frequencyData" :group="group" :assign_status="assign_status" @updateCount="updateCount" @updateStatus="updateStatus"/>
                </b-tab>

                <b-tab title="In Progress" lazy @click="tab_status = 'in_progress'" :active="tab_status == 'in_progress'">
                	<template #title>
						<div class="inline-button-items">
							<span>In Progress</span>
							<b-badge variant="success" style="margin-left: 5px;" v-if="in_progress > 0 ">
								<span>{{in_progress}}</span>
							</b-badge>
						</div>
                  </template>
                  <Pending :siteData="siteData" :locations="locations" :crew_filter="crew_filter" :status="status" :start="start" :end="end" :tab_status="'in_progress'" :frequencyData="frequencyData" :group="group" :assign_status="assign_status" @updateCount="updateCount" @updateStatus="updateStatus"/>
                </b-tab>

                <b-tab title="Validate" lazy @click="tab_status = 'validate'" :active="tab_status == 'validate'">
                	<template #title>
						<div class="inline-button-items">
							<span>Validate</span>
							<b-badge variant="info" style="margin-left: 5px;" v-if="validate > 0 ">
								<span>{{validate}}</span>
							</b-badge>
						</div>
                  	</template>
                  	<Pending :siteData="siteData" :locations="locations" :crew_filter="crew_filter" :status="status" :start="start" :end="end" :tab_status="'validate'" :frequencyData="frequencyData" :group="group" :assign_status="assign_status" @updateCount="updateCount" @updateStatus="updateStatus"/>
                </b-tab>

				
                <b-tab title="Failed" lazy @click="tab_status = 'failed'" :active="tab_status == 'failed'">
                  	<template #title>
						<div class="inline-button-items">
							<span>Failed</span>
							<b-badge variant="danger" style="margin-left: 5px;" v-if="failed > 0 ">
								<span>{{failed}}</span>
							</b-badge>
						</div>
                  	</template>
                  	<Pending :siteData="siteData" :locations="locations" :crew_filter="crew_filter" :status="status" :start="start" :end="end" :tab_status="'failed'" :frequencyData="frequencyData" :group="group" :assign_status="assign_status" @updateCount="updateCount" @updateStatus="updateStatus"/>
                </b-tab>


                <b-tab title="Completed" lazy @click="tab_status = 'closed'" :active="tab_status == 'closed'">
                	<template #title>
                    <span>Completed</span>                    
                  </template>					
                  <Completed :siteData="siteData" :locations="locations" :crew_filter="crew_filter" :status="status" :start="start" :end="end" :frequencyData="frequencyData" :group="group" :assign_status="assign_status" @updateCount="updateCount" @updateStatus="updateStatus"/>
                </b-tab>


                <b-tab title="All" @click="tab_status = 'all'" :active="tab_status == 'all'">
                  <template #title>
                    <span>All</span>
                  </template>
                  <Pending :siteData="siteData" :locations="locations" :crew_filter="crew_filter" :status="status" :start="start" :end="end" :tab_status="'all'" :frequencyData="frequencyData" :group="group" :assign_status="assign_status" @updateCount="updateCount" @updateStatus="updateStatus"/>
                </b-tab>
            </b-tabs>
		</b-card>

		<b-modal
			id="signatureModel"
			ref="signatureModel"
			centered
			title="Signature"
			no-close-on-backdrop
			hide-footer
		>
			<b-row>
				<b-col md="12">
					<VueSignaturePad
						id="signature"
						width="100%"
						height="300px"
						ref="signaturePad"
						:options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3}"
					/>
				</b-col>
			</b-row>

			<b-row class="mt-2">
				<b-col md="3">
					<b-button variant="danger" class="mt-0" @click="clearSignature">
						<span class="text-nowrap">Clear</span>
					</b-button>
				</b-col>

				<b-col md="3">
					<b-button variant="warning" class="mt-0" @click="saveSignature">
						<span class="text-nowrap">Submit</span>
					</b-button>
				</b-col>
			</b-row>

		</b-modal>

	</div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, 
  BDropdownItem, BPagination, BCardBody, BFormCheckbox, BAlert, BForm, BBreadcrumb, BFormDatepicker,
  BTabs, BTab, BFormSelect, BFormSelectOption, VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import moment from "moment-timezone";
import Pending from './Pending.vue';
import Completed from './Completed.vue';
import Bus from "../../../event-bus";
import flatPickr from 'vue-flatpickr-component';

export default {
	components: {
		BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown,
		BDropdownItem, BPagination, BFormCheckbox, vSelect, BAlert, BForm, BBreadcrumb, BFormDatepicker,
		BTabs, BTab, BFormSelect, BFormSelectOption, Completed, Pending,flatPickr
	},
	directives: {
		'b-tooltip': VBTooltip	
	},

	data() {
		return {
			items:[],
			isSortDirDesc: true,
			perPageOptions: [10, 20, 50, 100],
			perPage : 10,
			sortBy : 'order',
			webUrl: process.env.VUE_APP_SERVER_URL,
			totalRecords : 0,
			currentPage : 1,
			searchQuery:'',
			from:null,
			to:null,
			siteData:null,
			selectAll:false,
			selected:[],
			allCrews:[],
			allAssignCrews:[],
			crew:[],
			yearData:[],
			sites:[],
			tab_status:'open',

			locations: [],
			allLocations: [],
			crew_filter: '',
			allFilterCrews: [],
			status: '',

			types:['Ad-Hoc','Reactive','Urgent'],
      		type:null,

			start:'',
        	end:'',
			popshowDismissibleAlert:false,
			error_message:'',
			date_config:{ 
	          mode: 'range',
	          dateFormat: 'd M Y',
	          defaultDate: [moment.tz('Asia/Singapore').format('DD MMM YYYY'), moment.tz('Asia/Singapore').format('DD MMM YYYY')]
	        },

	        date_range:moment.tz('Asia/Singapore').format('DD MMM YYYY') + ' to ' + moment.tz('Asia/Singapore').format('DD MMM YYYY'),
	        // date_range:'',
	        
	        open:0,
			in_progress:0,
			validate:0,
			failed:0,

			frequencies:[],
			frequencyData:null,
			groupArray:[],
			group:null,
			dateFilter:'periodic',
			assign_status:'all'
		}
	},

	watch: { 
        tab_status: function(newVal, oldVal) {
			Bus.$emit('refreshTable');
        }
	},

	methods : {
		variantColor(status){
			if (status == 'active') {
				return 'success';
			}else{
				return 'primary';
			}
		},

		variantProgressColor(status){
			if (status == 'open') {
				return 'primary';
			}else if(status == 'in_progress'){
				return 'warning'
			}else if(status == 'validate'){
				return 'info'
			}else if(status == 'failed'){
				return 'danger'
			}else{
				return 'success';
			}
		},
		
		allSites(){
			return this.$store.dispatch(POST_API, {
				data:{
					role:this.$store.getters.currentUser.role,
					om_sites:this.$store.getters.currentUser.om_sites,
				},
				api: '/api/all-sites'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
				} else {
					this.sites = this.$store.getters.getResults.data;
					// if (this.sites.length == 1 ) {
					// 	this.siteData = this.sites[0];
					// }
					var obj = {
						_id:'all-site',
						site_name:'All Project Sites',
						pdf_date_format: 'DD MMM YYYY',
						pdf_time_format: 'HH:mm'
					}

					this.sites.unshift(obj);
					
					this.siteData = this.defaultSite(this.sites);

					this.allFilterCrewsList();
					this.getSiteGroup();
					

					if (this.$route.params.site && this.$route.params.site != 'null') {			
						var site = this.$route.params.site;
						var selectedSite = [];
						
						this.sites.forEach(function(item){
							if (item._id == site) {
								selectedSite.push(item);
							}
						})

						this.siteData = selectedSite[0];
						
						this.allLocationsList().then(()=>{
							if (this.$route.params.location && this.$route.params.location != 'null') {
								var t_location = [];

								this.$route.params.location.split(',').forEach((t1) => {
									this.allLocations.forEach((li) => {
										if(li._id == t1){
											t_location.push(li)
										}
									})
								})
								this.locations = t_location;
							}

							if(this.siteData){
								this.allFilterCrewsList().then(() => {
									if (this.$route.params.crew && this.$route.params.crew != 'null') {
										this.allFilterCrews.forEach((cr) => {
											if (cr._id == this.$route.params.crew) {
												this.crew_filter = this.$route.params.crew;
											}
										})
									}
								});
							} else {
								this.allFilterCrews = [];
							}
						});

						this.getSiteGroup().then(()=>{
							if (this.$route.params.group && this.$route.params.group != 'null') {
								this.group = JSON.parse(this.$route.params.group);
							}
						});

					}
					return this.sites;
				}
			});
		},

		/* retainData(){
			// console.log(this.$route.params);

			if (this.$route.params.keyword && this.$route.params.keyword != 'null') {
				this.searchQuery = this.$route.params.keyword.replace(/_/g,' ');
			}
			if (this.$route.params.sortBy && this.$route.params.sortBy != 'null') {
				this.sortBy = this.$route.params.sortBy;
			}
			if (this.$route.params.sortDirection && this.$route.params.sortDirection != 'null') {
				this.isSortDirDesc = this.$route.params.sortDirection =='true' ? true : false;
			}
			
			var statusArr = ['open', 'in_progress', 'validate', 'closed', 'failed']

			if ( this.$route.params.status && this.$route.params.status != 'null' && statusArr.includes(this.$route.params.status) ) {
				this.status = this.$route.params.status;
			} else {
				this.status = '';
			}
			
			if (this.$route.params.start && this.$route.params.start != 'null') {
				
				this.start = moment(this.$route.params.start).format('YYYY-MM-DD');
			}
			if (this.$route.params.end && this.$route.params.end != 'null') {
				this.end = moment(this.$route.params.end).format('YYYY-MM-DD');
			}

			if ((this.start != '' && this.start != null) && (this.end != '' && this.end != null)) {

				this.date_range = moment(this.start).tz('Asia/Singapore').format('DD MMM YYYY') +' to '+moment(this.end).tz('Asia/Singapore').format('DD MMM YYYY')
			}


			if (this.$route.params.tab_status && this.$route.params.tab_status != 'null') {
				this.tab_status = this.$route.params.tab_status;
			}

			this.allSites().then(() => {
				// this.filterTable();
			});
		}, */

		retainData(){			
			if (this.$route.params.start && this.$route.params.start != 'null') {
				this.start = moment(this.$route.params.start).format('YYYY-MM-DD');
			}
			if (this.$route.params.end && this.$route.params.end != 'null') {
				this.end = moment(this.$route.params.end).format('YYYY-MM-DD');
			}

			if ((this.start != '' && this.start != null) && (this.end != '' && this.end != null)) {
				this.date_range = moment(this.start).tz('Asia/Singapore').format('DD MMM YYYY') +' to '+moment(this.end).tz('Asia/Singapore').format('DD MMM YYYY')
			}

			if (this.$route.params.frequency && this.$route.params.frequency != 'null') {
				this.frequency = this.$route.params.frequency;
			}
			
			if (this.$route.params.tab_status && this.$route.params.tab_status != 'null') {
				this.tab_status = this.$route.params.tab_status;
			}

			if (this.$route.params.assign_status && this.$route.params.assign_status != 'null') {
				this.assign_status = this.$route.params.assign_status;
			}

			this.allSites();
		},

		clearSignature(){
			this.$refs.signaturePad.clearSignature();
		},

		saveSignature(){
			const { isEmpty, data } = this.$refs.signaturePad.saveSignature();		
			if (isEmpty) {
				Swal.fire({
					position: 'center',
					icon: 'warning',
					title: 'Signature is required',
					showConfirmButton: false,
					timer: 1500
				})
			}else{
				var imageData = {
					image : data,
					name : 'signature.png',
					type: 'client',
					default:'no'
				}			
				this.markClosedTask(imageData)
				this.$refs['signatureModel'].hide();
			}
		},

		openSignatureBox(){
			this.$refs['signatureModel'].show();
		},		

		updateStart(){
			this.end = moment(this.start).tz('Asia/Singapore').toDate();
			// this.filterTable();
		},

		disabledDates(){
			if (this.start) {
				return moment(this.start).format('YYYY-MM-DD');
			}else{
				return moment().format('YYYY-MM-DD');				
			}
		},

		resetData(){
			this.locations = [];
			this.crew_filter = '';

			this.frequencyData = null;

			this.allLocations = [];
			this.allFilterCrews =[];
			this.groupArray =[];
      		this.group =null;

			if (this.siteData != null && this.siteData._id != 'all-site') {
				this.getSiteGroup();
			}
			
			this.allLocationsList();
			if(this.siteData){
				this.allFilterCrewsList();
			}
			// this.filterTable()
		},

		allLocationsList(){
			return this.$store.dispatch(POST_API, {
				data:{
				site:this.siteData ? this.siteData._id : null,
				},
				api: '/api/all-locations'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
					this.showDismissibleAlert = true;
					window.scrollTo(0,0);
				} else {
					this.showDismissibleAlert = false;
					var data  = this.$store.getters.getResults.data;
					
					this.allLocations = data;

					if (this.$route.params.locations && this.$route.params.locations != 'null') {
			
						var locations = this.$route.params.locations.split(',');

						var selectedLocation = [];
						
						this.allLocations.forEach(function(item){
							
							if (locations.indexOf(item._id) >= 0) {
								selectedLocation.push(item);
							}
						})
						this.locations = selectedLocation;
					}
					return this.allLocations;
				}
			});
		},

		allFilterCrewsList(){
			return this.$store.dispatch(POST_API, {
				data:{
					site:this.siteData ? this.siteData._id : null,
				},
				api: '/api/all-crew'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
					this.showDismissibleAlert = true;
				} else {
					this.showDismissibleAlert = false;
					var data  = this.$store.getters.getResults.data;
					this.allFilterCrews = data;
					return this.allFilterCrews;
				}
			});
		},

		breadCrumb(){
			var item = [{
				to:{name:'client-dashboard'},
				text: 'Dashboard',
			},{
				to:null,
				text: 'Schedule Work'
			},{
				to:null,
				text: 'Tasks',
				active:true
			}];
			return item;
		},
		
		setDate(){
			var date = this.date_range.split(' to ');

	        if (date[1]) {
	            this.start = moment(new Date(date[0])).format('YYYY-MM-DD');
	            this.end = moment(new Date(date[1])).format('YYYY-MM-DD');
	        }else{
	            this.start = moment(new Date(date[0])).format('YYYY-MM-DD');
	            this.end = moment(new Date(date[0])).format('YYYY-MM-DD');
	        }
		},
		updateCount(value){
			
			//console.log(value);

			this.open 			= value.open;
			this.in_progress 	= value.in_progress;
			this.validate 		= value.validate;
			this.failed 		= value.failed;
		},

		allFrequencies(){
			return this.$store.dispatch(POST_API, {
				data:{},
				api: '/api/all-periodic-frequencies'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
					this.showDismissibleAlert = true;
					window.scrollTo(0,0);
				} else {
					this.showDismissibleAlert = false;
					var data  = this.$store.getters.getResults.data;
					
					this.frequencies = data;
					/*this.frequencies.push({name:'Routine'});
					this.frequencies.push({name:'Reactive'});*/

					if (this.$route.params.frequencyData && this.$route.params.frequencyData != 'null') {
						var freq = this.$route.params.frequencyData;
						var selectedFreq = null;
						
						this.frequencies.forEach(function(item){
							if (item.name == freq) {
								selectedFreq = item;
							}
						})
						this.frequencyData = selectedFreq;
					}


					return this.frequencies;
				}
			});
		},

		getSiteGroup(){
			return this.$store.dispatch(POST_API, {
				data:{
					site:this.siteData ? this.siteData._id : null,
				},
				api: '/api/task-group-suggestions'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
					this.showDismissibleAlert = true;
					//window.scrollTo(0,0);
				} else {
					this.showDismissibleAlert = false;
					var data  = this.$store.getters.getResults.data;
					
					this.groupArray = data;
					return this.groupArray;
				}
			});
		},

		updateStatus(value){
			this.tab_status = value;
		}
	},

	mounted() {
		// this.allSites();
		this.assign_status = this.$store.getters.currentUser.role == 'crew' ? 'myself':'all'
		
		this.allFrequencies().then(() => {
			this.retainData();
		});
		this.setDate();

	}
  
}
</script>

<style lang="scss" scoped>
	.per-page-selector {
		width: 90px;
	}
	.wordBreak2{
		text-align: justify !important;
		text-justify: inter-word !important;
	}
	.inline-button-items{
		display: inline-flex;
	}
</style>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-select.scss';
	@import '@core/scss/vue/libs/vue-flatpicker.scss';

	.nav-item .nav-link{
		height: 44.28px;
	}
</style>